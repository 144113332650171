<template>
  <div @click="cancelAdd" class="ym-mobilebar">
    <div v-if="disabledSidebar" class="sidebarOverlay"></div>
    <ul v-if="dictionary && Object.keys(dictionary).length > 0">
      <router-link tag="li" to="/service"
        ><a
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-tool"
          >
            <path
              d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"
            ></path></svg
          ><span>Zlecenia</span></a
        ></router-link
      >
      <router-link tag="li" to="/records/machinery"
        ><a
          ><icon name="settings"></icon
          ><span>{{
            dictionary["table_order_devices_type_field_label"]
          }}</span></a
        ></router-link
      >
      <router-link tag="li" to="/records/customer"
        ><a><icon name="users"></icon><span>Klienci</span></a></router-link
      >
      <router-link tag="li" to="/records/services"
        ><a
          ><icon name="trending-up"></icon
          ><span>{{
            dictionary["table_order_is_services_field_label"]
          }}</span></a
        ></router-link
      >
      <router-link tag="li" to="/notes"
        ><a><icon name="info"></icon><span>Notatki</span></a></router-link
      >
      <router-link tag="li" to="/settings/"
        ><a
          ><icon name="more-horizontal"></icon><span>Więcej</span></a
        ></router-link
      >
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import YMmodal from "@/components/Modal.vue";

export default {
  name: "Sidebar",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      disabledSidebar: "disabledSidebar",
      dictionary: "dictionary",
    }),
  },
  methods: {
    ...mapActions({
      authLogout: "auth/authLogout",
    }),
    logout: function () {
      this.authLogout().then(() => location.reload());
    },
    cancelAdd() {
      if (this.disabledSidebar) {
        this.$buefy.modal.open({
          parent: this,
          component: YMmodal,
          hasModalCard: true,
          props: {
            title: "Czy na pewno chcesz przerwać",
            content: "",
            cancel: "Nie",
            ymHasIcon: true,
            ymIcon: "x",
            submit: `<span class="mal50i">Przerwij</span>`,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ym-mobilebar {
  z-index: 133;
}
.sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 34;
}
</style>
