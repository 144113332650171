<template>
  <div class="sidebar-note">
    <div class="sidebar-note-title">
      Od: <b>{{ note.sender }}</b
      ><br />
      Wysłane: <b>{{ note.created_at }}</b>
    </div>
    <div class="sidebar-note-content">{{ note.note }}</div>
    <div @click="readed" class="button is-small is-dark">Przeczytałem</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      getUserNotes: "auth/userNotes",
      getUserNoteCall: "records/getUserNote",
    }),

    readed() {
      this.getUserNoteCall({ id: this.note.id }).then(() => {
        this.getUserNotes();
      });
    },
  },
};
</script>

<style scoped>
@media print, screen and (min-width: 769px) {
  .modal-card {
    min-width: 320px;
  }
}
.sidebar-note {
  position: relative;
  background: #ffa;
  padding: 8px;
  box-shadow: 0 5px 6px 3px rgba(0, 0, 0, 0.2);
}
.sidebar-note + .sidebar-note {
  margin-top: 16px;
}

.sidebar-note-title {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #020202;
}

.sidebar-note-content {
  font-size: 14px;
}

.button {
  margin-top: 8px;
}
</style>
