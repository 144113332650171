import { $axios } from "@/utils/axios";

export default {
  namespaced: true,

  state: {
    token: JSON.parse(localStorage.getItem("agro-token")) || "",
    user: JSON.parse(localStorage.getItem("agro-user")) || null,
    status: JSON.parse(localStorage.getItem("agro-status")) || null,
    notes: null,
  },

  getters: {
    isAuthenticated: (state) => !!state.token,
    user: (state) => state.user,
    userId: (state) => state.user.id,
    status: (state) => state.status,
    notes: (state) => state.notes,
    notes4me: (state) =>
      state.notes
        ? state.notes.filter((note) =>
            note.recipients.some(
              (recipient) =>
                recipient.id === state.user.id && recipient.readed_at === null
            )
          )
        : [],
  },

  mutations: {
    authSuccess: (state, resp) => {
      state.token = resp.data.access_token;
    },
    userSuccess: (state, resp) => {
      state.user = resp;
    },
    status: (state, resp) => {
      state.status = resp;
    },
    notes: (state, resp) => {
      state.notes = resp;
    },
  },

  actions: {
    authRequest: ({ commit, dispatch }, user) => {
      dispatch("setLoading", true, { root: true });
      return new Promise((resolve, reject) => {
        $axios
          .post("/auth", user)
          .then((resp) => {
            localStorage.setItem(
              "agro-token",
              JSON.stringify(resp.data.access_token)
            );
            // $axios.defaults.headers.common['Authorization'] = `Bearer ${resp.data.access_token}`
            commit("authSuccess", resp);
            Promise.all([dispatch("userDetails"), dispatch("apiStatus")]).then(
              () => resolve()
            );
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    authLogout: ({ dispatch }) => {
      dispatch("setLoading", true, { root: true });

      return new Promise((resolve) => {
        localStorage.removeItem("agro-token");
        localStorage.removeItem("agro-user");
        localStorage.removeItem("agro-status");
        resolve();
      });
    },
    passwordRemind: ({ dispatch }, user) => {
      dispatch("setLoading", true, { root: true });
      return new Promise((resolve, reject) => {
        $axios
          .post("/password_remind", user)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    passwordSet: ({ dispatch }, user) => {
      dispatch("setLoading", true, { root: true });
      return new Promise((resolve, reject) => {
        $axios
          .post("/password_set", user)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    userNotes: ({ commit, dispatch, rootState }, payload) => {
      dispatch("setLoading", true, { root: true });

      const params = [
        `access_token=${rootState.auth.token}`,
        `page=${payload ? payload.page : 1}`,
        `take=${payload ? payload.perPage : 10}`,
        `sort_field=${payload ? payload.sortField : "created_at"}`,
        `sort_order=${payload ? payload.sortOrder : "desc"}`,
        `search=${payload ? payload.searchQuery : ""}`,
        `search_deleted=${payload ? payload.searchDeleted : 0}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        $axios
          .get(`/note?${params}`)
          .then((resp) => {
            resolve(resp.data);
            commit("notes", resp.data.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
    userDetails: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        const params = [`access_token=${state.token}`].join("&");
        $axios
          .get(`/me?${params}`)
          .then((resp) => {
            localStorage.setItem("agro-user", JSON.stringify(resp.data));
            commit("userSuccess", resp.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    apiStatus: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        const params = [
          `access_token=${state.token}`,
          `page=1`,
          `take=30`,
          `sort_field=name`,
          `sort_order=asc`,
          `search=`,
          `search_deleted=0`,
        ].join("&");
        $axios
          .get(`/status?${params}`)
          .then((resp) => {
            localStorage.setItem("agro-status", JSON.stringify(resp.data.data));
            commit("status", resp.data.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    userUpdate: ({ dispatch, commit, state }, user) => {
      dispatch("setLoading", true, { root: true });
      return new Promise((resolve, reject) => {
        $axios
          .put(`/user/${user.id}`, { ...user, access_token: `${state.token}` })
          .then((resp) => {
            localStorage.setItem("agro-user", JSON.stringify(resp.data.data));
            commit("userSuccess", resp.data.data);
            resolve();
          })
          .catch((err) => {
            if (err.status === 401) {
              dispatch("auth/authLogout", null, { root: true }).then(() =>
                location.reload()
              );
            }
            reject(err);
          })
          .finally(() => dispatch("setLoading", false, { root: true }));
      });
    },
  },
};
