<template>
  <div id="app">
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="ym-mobile-wrap">
      <div class="container ym-container">
        <template v-if="isAuthenticated">
          <Mobilebar />
          <div v-if="notes4me && notes4me.length > 0" style="padding: 16px">
            <SidebarNote v-for="note in notes4me" :key="note.id" :note="note" />
          </div>
        </template>
        <div class="ym-logo" v-else>
          <h1>
            <router-link
              to="/"
              :style="{
                'background-image': `url(${storage?.content}${logo?.content})`,
              }"
              >SERVICEAPP</router-link
            >
          </h1>
        </div>
        <!-- <section class="section" v-if="isAuthenticated">
          <div class="userlogout"><span v-if="user">{{ user.firmname }}: {{ user.firstname }} {{ user.lastname }}</span><a @click="logout"><icon name="log-out"></icon></a></div>
          <portal to="BreadCrumbs"></portal>
        </section>                 -->
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Mobilebar from "@/components/Mobilebar.vue";
import { mapActions, mapGetters } from "vuex";
import { $axios } from "@/utils/axios";
import store from "./store";
import SidebarNote from "@/components/SidebarNote.vue";

// import {AUTH_LOGOUT} from '@/store/actions/auth'
// import { $axios } from '@/utils/axios'

export default {
  name: "app",
  components: {
    Mobilebar,
    SidebarNote,
  },
  data() {
    return {
      polling: null,
    };
  },
  watch: {
    isAuthenticated() {
      if (this.isAuthenticated) {
        this.getUserNotes();
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      isAuthenticated: "auth/isAuthenticated",
      user: "auth/user",
      storage: "storage",
      logo: "logo",
      notes4me: "auth/notes4me",
    }),
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created: function () {
    this.getAppSettings();
    this.getAppDictionary();

    let isAuthenticated = this.isAuthenticated;
    if (isAuthenticated) {
      this.getUserNotes();
      this.pollData();
    }
    $axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (err) {
        if (
          err.response.status === 401 &&
          err.response.config &&
          !err.response.config.__isRetryRequest
        ) {
          if (isAuthenticated) {
            store.dispatch("auth/authLogout").then(() => {
              location.reload();
            });
          }
        }
        return Promise.reject(err.response);
      }
    );
  },
  methods: {
    ...mapActions({
      getAppSettings: "getAppSettings",
      getAppDictionary: "getAppDictionary",
      authLogout: "auth/authLogout",
      getUserNotes: "auth/userNotes",
    }),
    pollData() {
      this.polling = setInterval(() => {
        this.getUserNotes();
      }, 900000);
    },
    logout: function () {
      this.authLogout().then(() => location.reload());
    },
  },
};
</script>
