import { required, email, min_value } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "wymagane",
});

extend("email", {
  ...email,
  message: "niepoprawny adres",
});

extend("min_value", {
  ...min_value,
  message: "Wartość musi być równa bądź wyższa niż {min}",
});
