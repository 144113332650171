// import Rollbar from 'rollbar';

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PortalVue from "portal-vue";
import Buefy from "buefy";
import { VueFeatherIconsSsr as icon } from "vue-feather-icons-ssr";
import "./vee-validate";
import "./assets/scss/_main.scss";
import "floating-vue/dist/style.css";
import Maska from "maska";
import Hotjar from "vue-hotjar";
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
import { veLoading, VePagination, VeLocale } from "vue-easytable";
import enUS from "vue-easytable/libs/locale/lang/en-US.js";
import { vfmPlugin } from "vue-final-modal";
import FloatingVue from "floating-vue";

// Vue.prototype.$rollbar = new Rollbar({
//   accessToken: 'b0d56dd736d4479ab2f79187230829f5',
//   captureUncaught: true,
//   captureUnhandledRejections: true,
// });

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(PortalVue);
Vue.component("icon", icon);
Vue.use(VueEasytable);
Vue.use(VePagination);
Vue.use(Maska);
Vue.use(vfmPlugin);
Vue.use(FloatingVue);
Vue.prototype.$veLoading = veLoading;
VeLocale.use(enUS);
Vue.use(Hotjar, {
  id: "3378195",
  isProduction: true,
});

// Vue.config.errorHandler = (err, vm) => {
//   vm.$rollbar.error(err);
//   throw err;
// };

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
